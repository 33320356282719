<template>
    <header>
        <nav class="navbar bg-body-tertiary fixed-top shadow">
            <div class="container">
                <div class="d-flex justify-content-start align-items-center gap-2">
                    <RouterLink class="navbar-brand" to="/clips">
                    <img src="@/assets/logo.svg" alt="Logo" width="25" height="25" class="d-inline-block align-text-top">
                    Zunder
                    </Routerlink>
                    <RouterLink aria-current="page" class="nav-link active" to="/clips" v-if="userStore.isUser">Clips</RouterLink>
                    <RouterLink aria-current="page" class="nav-link active" to="/users" v-if="userStore.isOrganization">Usuarios</RouterLink>
                    <RouterLink aria-current="page" class="nav-link active" to="/admin/users" v-if="userStore.isSuperAdmin">Admin</RouterLink>
                </div>

                <div class="d-flex justify-content-end align-items-center gap-2">
                    <BDropdown v-model="dropdownUser" size="sm" variant="link" toggle-class="text-decoration-none" no-caret toggleClass="pb-0">
                        <template #button-content>
                            <div class="avatar-circle" :style="{ backgroundColor: userStore.user.userColor }">
                                <p class="avatar-circle-inner">{{ userStore.initials }}</p>
                            </div>
                        </template>
                        <BDropdownItem to="/settings">Preferencias</BDropdownItem>
                        <BDropdownItem @click="logout">Salir</BDropdownItem>
                    </BDropdown>
                </div>
                
            </div>
        </nav>
    </header>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();
const router = useRouter();
const dropdownUser = ref(false);

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    userStore.setUser({});
    router.push({ name: 'login' });
};


</script>

<style lang="scss" scoped></style>