import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createBootstrap } from 'bootstrap-vue-next'
import * as Sentry from "@sentry/vue";




// Import all of Bootstrap's JS
// import * as bootstrap from 'bootstrap' 
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

// Import our custom CSS
import './assets/scss/styles.scss'

import App from './App.vue'
import router from './router'




const app = createApp(App)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
      app,
      dsn: "https://a49a1652fb0835687083d8486a907703@o4507215686860800.ingest.de.sentry.io/4507215690924112",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
  
app.use(createPinia())
app.use(router)
app.use(createBootstrap())
app.mount('#app')
