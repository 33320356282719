import { reactive } from "vue";
import { io } from "socket.io-client";

const socketapiurl = import.meta.env.VITE_API_SOCKET_URL;

export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: []
});

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === "production" ? undefined : socketapiurl;

export const socket = io(URL, {
  auth: {
    userId: JSON.parse(localStorage.getItem('user')).id
  }
});

socket.on("connect", () => {
  state.connected = true;
  // Emitimos un evento para registrar este socket con el ID del usuario
  socket.emit('register', JSON.parse(localStorage.getItem('user')).id);
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.on("foo:user", (...args) => {
  state.fooEvents.push(args);
});

socket.on("bar:user", (...args) => {
  state.barEvents.push(args);
});

// socket.on('updateEventList', () => { console.log("Recibido updateEventList")});
